import "./style.scss";
import React, { useContext, useEffect, useState, useRef, KeyboardEvent } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import IframeResizer from 'iframe-resizer-react'


const About = () => {
  const [stateIframeShow, setstateIframeShow] = useState(false)
  const [altura, setaltura] = useState(600)
  function scroolto(idElement: string) {
    
    const element = document.getElementById(idElement);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  function openiframeConsulta() {
    setstateIframeShow(true);
    scroolto("entrarButton");
  }

  var location: any = useLocation();

  function checkgotoForm() {
    if ((location.state != null) && (location.state.gotoForm)) {
      scroolto('formLeads')
      location.state.gotoForm = false;
    }

  }

  const interval = setInterval(function () {
    checkgotoForm();
  }, 10);



  // const interval2 = setInterval(function () {
  //   if(ref.current!=null){
  //     ref.current.resize();
  //     setaltura(1030);
  //   }
  // }, 1000);

  const ref:any = useRef(null);

  return (
    <>
      <div className="aboutPrincipal">
        <div className="row col-12 p-0 m-0">
          <div className="more">
            <div className="more_group">
              <div className="container" style={{paddingLeft:"0px", paddingRight:"0px", maxWidth:"1250px"}}>
              <div className="row">
            <div className="col-12 col-md-8 col-sm-12 item-content" style={{paddingLeft:"42px", paddingRight:"0px"}}>
            <h2 style={{color:"white", marginTop:"40px", marginBottom:"0px", fontSize:"40px"}}>Verified by GS1</h2>
            <h4 style={{color:"white", marginTop:"12px", fontSize:"24px"}}>Confiança e eficiência começam com uma identificação válida</h4>
            </div>

            <div className="col-12 col-md-4 col-sm-12 item-content" style={{textAlign:"right", paddingLeft:"0px", paddingRight:"0px"}}>
              <img src={require('../../../src/banner3.png')} style={{maxWidth:"600px", maxHeight:"210px"}} className="banner2"/>
            </div>
            </div>
            </div>
            </div>
          </div>

          <div className="row pt6" style={{ width: "100%" }}>
            <div className="container pt6">

              <div className="row"  >
                <div className="col-12 col-md-8 col-sm-12 item-content">
                  <h2>Obtenha a identidade correta dos produtos</h2>
                  <p id="entrarButton" style={{ maxWidth: "100%" }}>
                    Com o Verified by GS1 é possível validar a identidade de produtos nacionais e internacionais.
                    Nossa base de dados global garante a autenticidade das informações de produtos cadastradas diretamente pelos donos da marca.
                    <div><br></br>
                    </div>
                    Afinal, em uma realidade onde bilhões de códigos de barras são lidos todos os dias, a GS1, responsável pela atribuição desses códigos, disponibiliza ao mercado uma fonte única de consulta de dados confiáveis.

                  </p>
                  <p>
                    <button style={{ marginBottom: '13px' }} type="submit" value="Entrar" onClick={() => openiframeConsulta()}>Testar Agora</button>
                  </p>
                </div>
                <div className="col-12 col-md-4 item-content">
                  <div className="gs1-nav-left pt-6" style={{ float: "right" }}>
                    <nav className="navbar navbarHome navbar-default navbar-expand-lg" role="navigation" style={{ maxWidth: "100%" }}>
                      <div className="navbar-header">
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#bs-sub-menu" aria-controls="bs-sub-menu" aria-expanded="false" aria-label="Toggle navigation">
                          Navegação
                        </button>
                      </div>
                      <div className="collapse navbar-collapse navbar-collapse-industrylp" id="bs-sub-menu">
                        <div className="icon-menu text-center d-none d-lg-block"></div>
                        <div className="nav navbar-nav">
                          <div id="zz5_PlaceHolderMain" className=" noindex ms-core-listMenu-verticalBox">
                            <ul id="zz6_RootAspMenu" className="root ms-core-listMenu-root static">
                              <li className="static">
                                <a style={{ display: "block" }} className="static menu-item ms-core-listMenu-item ms-displayInline ms-navedit-linkNode" tabIndex={0} accessKey="1">
                                  <span className="additional-background ms-navedit-flyoutArrow"><span className="menu-item-text">Destaques</span></span>
                                </a>
                                <ul className="static">
                                  <li className="static">
                                    <a style={{ display: "block" }} className="static menu-item ms-core-listMenu-item ms-displayInline ms-navedit-linkNode" tabIndex={0} onClick={() => scroolto('dados')}>
                                      <span className="additional-background ms-navedit-flyoutArrow"><span className="menu-item-text">Dados para consulta</span></span>
                                    </a>
                                  </li>
                                  <li className="static ">
                                    <a
                                      style={{ display: "block" }}
                                      className="static  menu-item ms-core-listMenu-item ms-displayInline  ms-navedit-linkNode"
                                      tabIndex={0}
                                      onClick={() => scroolto('consultas')}
                                    >
                                      <span className="additional-background ms-navedit-flyoutArrow"><span className="menu-item-text">Formas de consultar os dados</span></span>
                                    </a>
                                  </li>
                                  <li className="static">
                                    <a style={{ display: "block" }} onClick={() => scroolto('formLeads')}
                                      className="static menu-item ms-core-listMenu-item ms-displayInline ms-navedit-linkNode" tabIndex={0} >
                                      <span className="additional-background ms-navedit-flyoutArrow"><span className="menu-item-text">Como acessar o Verified by GS1</span></span>
                                    </a>
                                  </li>

                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {stateIframeShow &&
          <>
          <div className="row pt6" style={{ width: "100%", paddingLeft: "12px" }}>
            <div className="container pt6">
              <div className="row">
              <h1 style={{ fontSize: "32px" }}>Digite um número de código de barras</h1>
                <div className="col-12 col-md-12 col-sm-12 item-content pt-4">
                  <div id="iframeConsulta">
                     

                    <iframe 
                      src="https://www.gs1.org/services/verified-by-gs1/iframe" 
                      width="100%" 
                      height="600" 
                      loading='lazy'
                      title="Verified Search"
                      ></iframe>
                      <div>
                        <p style={{ fontSize: "15px" }}>This service is being accessed through <a href="https://www.gs1.org/" target="_blank">www.GS1.org</a>.</p>
                      </div>
                
                  </div>
                </div>
                 
                
              </div>
            </div>
          </div>
          <div className="row pt6" style={{ width: "100%", marginLeft: "0px", marginRight: "0px", paddingLeft: "0px" }}>
            <div className=" pt6" style={{ width: "100%" }}>
                <div className="  bg-color-ui-1 no-content" style={{ width: '100%', paddingBottom: "42px", backgroundColor: "#E5F0FC" }}>
                  <div className="container" >
                    <div className="" >
                      <div className="col-12 item-content">
                        <div className="ms-webpart-zone ms-fullWidth">
                          <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                            <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                              <div className="ms-WPBody">
                                <div className="ms-rtestate-field">
                                  <h2 className="pt-6">Consulte mais produtos e obtenha mais informações!</h2>
                                </div>
                                <div className="ms-clear">


                                  Esta versão do Verified by GS1 está limitada a 30 consultas diárias e apresenta as 7 principais informações de um produto.
                                  Se você deseja consultar mais produtos e ter acesso a essas e outras informações, consulte nossos planos e contrate agora a solução Verified by GS1 da GS1 Brasil.

                                </div>
                                <p>
                                  <button id="entrarButton" style={{ marginTop: '42px' }} type="submit" value="Entrar" onClick={() => scroolto('formLeads')}>Quero contratar!</button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              

            </div>
          </div>
          </>
          }
          <div className="row pt6" style={{ width: "100%" }}>
            <div className=" pt6" style={{ width: "100%" }}>


            </div>
          </div>
          <div className="row pt6" style={{ width: "100%" }}>
            <div className=" container" style={{ width: "100%" }}>
              <div className="pt-6" id="dados" >
                <h1 style={{ fontSize: "32px" }}>Dados disponíveis para consulta</h1>
                <div><br></br></div>
                <div>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3 ">
                      <div className="card card-h card-horizontal h-100 pt-2">
                        <div className="row no-gutters h-100">

                          <div className="col-3 position-relative "  >
                            <img src={require('../../../src/ICON_VBG_GPC-min.png')} className=" imgConsultaType img-fluid image-wrap ms-rtePosition-4" alt="" style={{ width: "277px", height: "135px", marginBottom: "25px" }} />
                            <div><br></br><br></br><br></br><br></br><br></br></div>
                          </div>
                          <div className="col-8 textConsultaType">
                            <div className=" h-100 ">
                              <h5 className="card-title mt-0" style={{ display: 'inline', fontWeight: '500' }}>Dados Cadastrais</h5>
                              <p>
                                <span className="ms-rteStyle-Normal" style={{ fontSize: "18px" }}>
                                  São as informações que identificam um produto, cadastradas pelo próprio dono da marca, como: Descrição do Produto, Marca, Conteúdo Líquido e Unidade de Medida, Peso Bruto e Unidade de Medida, Imagem e GPC (Descrição e Código).
                                </span>
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3 ">
                      <div className="card card-h card-horizontal h-100 pt-2">
                        <div className="row no-gutters h-100">

                          <div className="col-3 position-relative" >
                            <img src={require('../../../src/ICON_VBG_PAIS-min.png')} className=" imgConsultaType img-fluid image-wrap ms-rtePosition-4" alt="" style={{ width: "277px", height: "135px", marginBottom: "25px" }} />
                            <div><br></br><br></br><br></br><br></br><br></br></div>
                          </div>
                          <div className="col-8 textConsultaType">
                            <div className=" h-100 ">
                              <h5 className="card-title mt-0" style={{ display: 'inline', fontWeight: '500' }}>Dados Regulatórios</h5>
                              <p>
                                <span className="ms-rteStyle-Normal" style={{ fontSize: "18px" }}>
                                  Ao contratar a solução Verified by GS1 da GS1 Brasil, você também terá acesso aos dados regulatórios de produtos, como a Nomenclatura Comum Mercosul (NCM) e o Código Especificador da Substituição Tributária (CEST), códigos apresentados nas notas fiscais de produtos com substituição tributária.
                                </span>
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3 ">
                      <div className="card card-h card-horizontal h-100">
                        <div className="row no-gutters h-100 pt-2">

                          <div className="col-3 position-relative" >
                            <img src={require('../../../src/ICON_VBG_DESCRI-min.png')} className=" imgConsultaType img-fluid image-wrap ms-rtePosition-4" alt="" style={{ width: "277px", height: "135px", marginBottom: "25px" }} />
                            <div><br></br><br></br><br></br><br></br><br></br></div>
                          </div>
                          <div className="col-8 textConsultaType">
                            <div className=" h-100 ">
                              <h5 id="consultas" className="card-title mt-0" style={{ display: 'inline', fontWeight: '500' }}>Dados Fiscais</h5>
                              <p>
                                <span className="ms-rteStyle-Normal" style={{ fontSize: "18px" }}>
                                  A GS1 Brasil também disponibiliza o status de sincronização do produto com Cadastro Centralizado de GTINS (CCG), o banco de dados utilizado pelas Secretarias da Fazenda dos Estados (SEFAZ). Essa informação só poderá ser consultada após contratação de um dos planos Verified by GS1.
                                </span>
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container pt6" style={{paddingLeft:"0px"}}>







            <div className="pt-6">
              <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                  <div className="ms-rtestate-field"><h1>Formas de consultar os dados</h1><div></div></div><div className="ms-clear"></div>
                </div>
              </div>




            </div>
            <div className="row">
              <div className="col-12 col-md-4 my-4 item-content alt-card">
                <div className="ms-webpart-zone ms-fullWidth">
                  <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                    <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                      <div className="ms-WPBody "  ><div className="ms-rtestate-field"><div className="card card-h" >
                        <div className="row no-gutters">

                          <div className="col-md-12">
                            <img src={require('../../../src/dados-produtos-web.png')} className="img-fluid cardimagewrap ms-rtePosition-4" alt="" style={{ width: "100%" }} />
                            <div className="card-body" style={{ minHeight: "300px" }}>

                              <div className="badge gs1-background-color-orange-accessible">Facilidade</div>
                              <h5 className="card-title mt-0">Acesso Web​​</h5>
                              <p className="card-text" style={{ display: "block" }}>Faça o Login na nossa plataforma web e digite um GTIN na barra de busca para consultar um produto.​​​​</p>
                              <p className="card-text" style={{ display: "block" }}>A plataforma também permite pesquisar vários produtos simultaneamente, importando uma lista de GTINs nos formatos xlxs, csv, txt.</p></div></div>
                        </div>
                      </div></div><div className="ms-clear"></div></div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-12 col-md-4 my-4 item-content alt-card">
                <div className="ms-webpart-zone ms-fullWidth">
                  <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                    <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                      <div className="ms-WPBody "  ><div className="ms-rtestate-field"><div className="card card-h" >
                        <div className="row no-gutters">

                          <div className="col-md-12">
                            <img src={require('../../../src/dados-produtos-excel.png')} className="img-fluid cardimagewrap ms-rtePosition-4" alt="" style={{ width: "100%" }} />
                            <div className="card-body" style={{ minHeight: "300px" }}>
                              <div className="badge gs1-background-color-orange-accessible">Relatórios</div>
                              <h5 className="card-title mt-0">Excel​​</h5>
                              <p className="card-text" style={{ display: "block" }}>
                                Envie uma lista de GTINs e receba um relatório exclusivo dos dados cadastrais de produtos e validação da estrutura do GTIN.
                              </p>
                              <p className="card-text" style={{ display: "block" }}>
                                A análise é apresentada em um dashboard gerencial e os atributos são disponibilizados em uma tabela detalhada, em Excel.
                              </p></div></div>
                        </div>
                      </div></div><div className="ms-clear"></div></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 my-4 item-content alt-card">
                <div className="ms-webpart-zone ms-fullWidth">
                  <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                    <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                      <div className="ms-WPBody "  ><div className="ms-rtestate-field"><div className="card card-h" >
                        <div className="row no-gutters">

                          <div className="col-md-12">
                            <img src={require('../../../src/dados-produtos-api.png')} className="img-fluid cardimagewrap ms-rtePosition-4" alt="" style={{ width: "100%" }} />
                            <div className="card-body" style={{ minHeight: "300px" }}>
                              <div className="badge gs1-background-color-orange-accessible">Integração</div>
                              <h5 className="card-title mt-0">API​​</h5>
                              <p className="card-text" style={{ display: "block" }}>Consulte nossa base de dados cadastrais de produtos de forma automatizadas integrando sua API via URL e TOKEN.​​​​</p>
                              <p className="card-text" style={{ display: "block" }}> Os GTINs podem ser enviados de forma unitária ou em lote. </p></div></div>

                        </div>
                      </div></div><div className="ms-clear"></div></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* <div>
                <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                  <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                    <div className="w-100 ms-WPBody " >
                      <div className="ms-rtestate-field">
                        <h2>Prepare-se para o Verified by GS1</h2>
                        <p>Donos de marcas, varejistas, marketplaces e provedores de soluções estão convidados a aprender mais sobre como o Verified by GS1 pode ajudar a melhorar a experiência de compra, aumentando a confiança do consumidor, satisfação e fidelidade à marca. </p>
                      </div>
                      <div className="ms-clear"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="row">
                    <div className="col-12 col-md-4 my-4 item-content alt-card">
                      <div className="ms-webpart-zone ms-fullWidth">
                        <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                          <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                            <div className="ms-WPBody w-100" >
                              <div className="ms-rtestate-field">
                                <div className="card card-h" >
                                  <div className="row no-gutters">
                                    <div className="col-4 col-md-12 position-relative">

                                    </div>
                                    <div className="col-8 col-md-12">
                                      <div className="card-body">
                                        <div className="badge gs1-background-color-orange-accessible">Case</div>
                                        <h5 className="card-title mt-0">Bigbasket​</h5>
                                        <p className="card-text">The benefits of trusted product information for bigbasket and other Indian retailers and marketplaces.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ms-clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 my-4 item-content alt-card">
                      <div className="ms-webpart-zone ms-fullWidth">
                        <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                          <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                            <div className="ms-WPBody w-100" >
                              <div className="ms-rtestate-field">
                                <div className="card card-h" >
                                  <div className="row no-gutters">
                                    <div className="col-4 col-md-12 position-relative">

                                    </div>
                                    <div className="col-8 col-md-12">
                                      <div className="card-body">
                                        <div className="badge gs1-background-color-orange-accessible">Case</div>
                                        <h5 className="card-title mt-0">Bigbasket​</h5>
                                        <p className="card-text">The benefits of trusted product information for bigbasket and other Indian retailers and marketplaces.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ms-clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 my-4 item-content alt-card">
                      <div className="ms-webpart-zone ms-fullWidth">
                        <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                          <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                            <div className="ms-WPBody w-100" >
                              <div className="ms-rtestate-field">
                                <div className="card card-h" >
                                  <div className="row no-gutters">
                                    <div className="col-4 col-md-12 position-relative">

                                    </div>
                                    <div className="col-8 col-md-12">
                                      <div className="card-body">
                                        <div className="badge gs1-background-color-orange-accessible">Case</div>
                                        <h5 className="card-title mt-0">Bigbasket​</h5>
                                        <p className="card-text">The benefits of trusted product information for bigbasket and other Indian retailers and marketplaces.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ms-clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 my-4 item-content alt-card">
                      <div className="ms-webpart-zone ms-fullWidth">
                        <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                          <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                            <div className="ms-WPBody w-100" >
                              <div className="ms-rtestate-field">
                                <div className="card card-h" >
                                  <div className="row no-gutters">
                                    <div className="col-4 col-md-12 position-relative">

                                    </div>
                                    <div className="col-8 col-md-12">
                                      <div className="card-body">
                                        <div className="badge gs1-background-color-orange-accessible">Case</div>
                                        <h5 className="card-title mt-0">Bigbasket​</h5>
                                        <p className="card-text">The benefits of trusted product information for bigbasket and other Indian retailers and marketplaces.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ms-clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 my-4 item-content alt-card">
                      <div className="ms-webpart-zone ms-fullWidth">
                        <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                          <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                            <div className="ms-WPBody w-100" >
                              <div className="ms-rtestate-field">
                                <div className="card card-h" >
                                  <div className="row no-gutters">
                                    <div className="col-4 col-md-12 position-relative">

                                    </div>
                                    <div className="col-8 col-md-12">
                                      <div className="card-body">
                                        <div className="badge gs1-background-color-orange-accessible">Case</div>
                                        <h5 className="card-title mt-0">Bigbasket​</h5>
                                        <p className="card-text">The benefits of trusted product information for bigbasket and other Indian retailers and marketplaces.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ms-clear"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="container pt-6">
              <div className="row" id="formLeads">

                <div className="col-12 col-md-6 item-content">
                  <div className="col-12 item-content">
                    <div className="ms-webpart-zone ms-fullWidth">
                      <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                        <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                          <div className="ms-WPBody " >
                            <div className="ms-rtestate-field knowmoreTitle">
                              <h1 style={{ fontSize: "32px" }}>Entenda como acessar o Verified by GS1 e comece já a consultar dados de produtos.</h1>
                            </div>
                            <div className="ms-clear">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ms-webpart-zone ms-fullWidth">
                    <div className="s4-wpcell-plain ms-webpartzone-cell ms-webpart-cell-vertical ms-fullWidth ">
                      <div className="ms-webpart-chrome ms-webpart-chrome-vertical ms-webpart-chrome-fullWidth ">
                        <div className="ms-WPBody knowmoreText pt-4">
                          <div className="ms-rtestate-field">
                            <div>
                            </div><br></br>Quer receber uma proposta e&nbsp;saber mais sobre como os dados de produtos​ podem ajudar sua empresa?&nbsp;
                            <div>
                              <div><br></br>Preencha o formulário ao lado e&nbsp;nossa equipe de especialistas entrará em contato!
                              </div>
                            </div>
                            <div>
                              <div><br></br>Informações consistentes e qualificadas de produtos é com a GS1 Brasil.
                              </div>
                            </div>
                            <div>
                            </div>

                          </div>
                          <div className="ms-clear"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 item-content">

                  <div role="main" id="vbg-dce46b1be0189f694121"></div>
                  <Helmet>

                    <script type="text/javascript" async={true} src="https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js" ></script>

                    <script type="text/javascript" async={true} src="https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fd335luupugsy2.cloudfront.net%2Fjs%2Frdstation-forms%2Fstable%2Frdstation-forms.min.js&data=05%7C01%7Ccarolina.simoes%40gs1br.org%7Cba39800fe9fa4dcbfac308db5d5ccc89%7C5fd2099955364eeabeff3c3551484f09%7C1%7C0%7C638206425431390486%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=JyQ0YFqLkw0OHFzS%2FkVNia%2FQqHFfsXh0%2FHphDImNmPg%3D&reserved=0"></script>
                    <script type="text/javascript" async={true}>
                      {`
                      setTimeout(() => {
                        new RDStationForms('vbg-dce46b1be0189f694121', 'UA-19649291-3').createForm();
                    }, 1000);
                    `}


                    </script>
                  </Helmet>

                </div>
              </div>

            </div>

          </div>
        </div >
      </div>

               
    </>
  );
};

export default About;