import React from 'react';

const CompanyDetail = ({ data }: { data: any }) => {
  return (
    <>
      <div className='row px-3 detailBox'>
        <div className='col-12 col-md-12 mb-5'>
          <div className='row'>
            <h3 className='titleCompanyDetail'>Informação sobre a empresa que licenciou esta chave GS1</h3>
          </div>
          <div className='row section pb-5 glnfntw'>
            <div className='col-12 col-md-12 table-responsive'>
              <table className='table'>
                <tbody>
                  <tr>
                    <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                      Nome da empresa
                    </td>
                    <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                      {data.licenseeName}
                    </td>
                  </tr>
                  <tr>
                    <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                      Endereço
                    </td>
                    <td className="propertyvalueCustom borderbottom" style={{ border: 'none', lineHeight: '13px' }}>
                      {data.address!==undefined && data.address !== "Não cadastrado" ? (
                        <>
                          {data.address.streetAddress!==undefined ? (
                            <>
                            <p>{data.address.streetAddress.value}</p>
                            </>
                          ):""}
                          {data.address.addressRegion!==undefined ? (
                            <>
                            <p>{data.address.addressRegion.value}</p>
                            </>
                          ):""}
                          {data.address.postalCode!==undefined ? (
                            <>
                            <p>{data.address.postalCode}</p>
                            </>
                          ):""}
                          {data.address.countryCode!==undefined ? (
                            <>
                            <p>{data.address.countryCode}</p>
                            </>
                          ):""}
                        </>
                      ) : (
                        <span>Não cadastrado</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                      Site
                    </td>
                    <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                    
                    { data.site || "Não cadastrado"}
                    </td>
                  </tr>
                  <tr>
                    <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                      Tipo de Prefixo
                    </td>
                    <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                      {data.licenceType}
                    </td>
                  </tr>
                  <tr>
                    <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                      Global Location Number (GLN)
                    </td>
                    <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                    {data.orgMenbroGs1 || "Não cadastrado"}
                    </td>
                  </tr>
                  <tr>
                    <td className="propertyCompany borderbottom" style={{ border: 'none' }}>
                      Organização Membro GS1
                    </td>
                    <td className="propertyvalue borderbottom" style={{ border: 'none' }}>
                     
                      {data.licensingMO.moName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='row mb-2 pl-3 pr-3'>
            <span className="lineInfo">{data.msgDate}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetail;
