import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import "./style.css"
// import "../../../global.style.css"


import Warning from '../../../../components/Warning';
import { LoginContext } from '../../../../contexts/login_context';
import { dismissToast, showError, showWait } from '../../../../services/toast';
import { useSelector } from '../../../../native-state.dev';
import DataTable, { TableColumn} from 'react-data-table-component';
import BootyPagination from '../../../../components/Pagination';
import NewWarning from '../../../../components/newWarning';
import { getVerifyCompanies } from '../../../../services/getVerifyCompanies';
import { countries, getCountryName } from '../../../../services/countryName';
import CompanyDetail from '../../../../components/companyresult/companyDetail';
import CompanyResults from '../../../../components/companyresult';
import ExportDetail from '../../../../components/ExportDetail';





const SearchOneFindCompany = () => {
  const [modalObje,setState] = useSelector((s: { searchDone: any; })=>s.searchDone);
  const [view, setView] = useState({ error: false, content: false });
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const { jwt } = useContext(LoginContext);
  const [code, setCode] = useState("");
  const [valueCep, setValueCep] = useState("");
  const [valueCity, setValueCity] = useState("");
  const [valueAdress, setValueAdress] = useState("");
  const [country, setCountry] = useState<any>(null);
  const [ShowIcon, setShowIcon] = useState(true)
  const [ShowImage, setShowImage] = useState('')
  const [ShowText, setShowText] = useState('')
  const [isFocused, setFocused] = useState(false)
  const [searchResultText, setSearchResultText] = useState('');
  const [showDataTable, setShowDataTable] = useState(true);

  const myRef: any = useRef();

  let navigate = useNavigate();
  let location = useLocation();

  const [extension, setExtension] = useState('');
  const [stateModal, setStateModal] = useState(false);
  const [exportButtonStatus, setExportButtonStatus] = useState(false);
  const [details, setDetails] = useState<any | null>(null);

  const open = () => {

    setExportButtonStatus(false);
    setStateModal(true)
    setExtension('')
  }

  const close = () => {
    setStateModal(false)
  }
  
  const iconMinus = '/icon-minus.svg';
  const iconPlus = '/icons-plus.svg';
  
  const [arrCompanies, setArrCompanies] = useState<any>();
  


  const [data, setData] = useState<any>();
  const [dataPrepared, setdataPrepared] = useState<any>();


  const conditionalRowStyles = [
    {
      when: (row: { multiple: boolean; }) => row.multiple === true,
      style: {
        borderBottomWidth: "0px"
      },

    },
    {
      when: (row: { multiple: boolean; }) => row.multiple === false,
      style: {
        borderBottomWidth: "0px",
        borderBottomColor: "#F0F5FA"
      },

    },
    {
      when: (row: { lastmultiple: boolean; }) => row.lastmultiple === true,
      style: {
        borderBottomWidth: "0px",
        borderBottomColor: "#F0F5FA"
      },

    },

    {
      when: (row: { hasError: boolean; }) => row.hasError === true,
      style: {
        backgroundColor: '#FFDFDF',
        color: '#BA1B23',
        '&:hover': {
          backgroundColor: '#BA1B23',
          color: '#FFDFDF'
        },
      },
    },
  ];
  

  const customStyles = {
    rows: {
      style: {
        display:'flex',
        justifyContent: 'space-between',
        minHeight: '50px', // Altura da linha
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: "0.94rem",
        color: "#002c6c",
        '&:nth-of-type(odd)': { 
          backgroundColor: '#ffffff', // Branco para linhas ímpares
        },
        '&:nth-of-type(even)': {
          backgroundColor: '#e4e8ea', // Cinza claro para linhas pares
        }
      },
    },
    headRow: {
      style:{
        display: 'flex',
        alignItems:'center',
        justifyContent: 'space-between',
      }
    },
    TableCell:{
      style:{
      color:'red'
    }},
    headCells: {
      style: {
        paddingLeft: '6px',
        paddingRight: '6px',
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: "0.94rem",
        color: "#002c6c",
      },
    },
    cells: {
      style: {
        paddingLeft: '6px',
        paddingRight: '6px',
        fontWeight: "500 !important",
        display: 'flex',
        justifyContent: '',
      },
    },
    pagination: {
      style: {
        color: "#002c6c",
        fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'",
        fontSize: '0.94rem',
        minHeight: '56px',
      },
    },
  };
  

  const showDetails = (licenseeName: string) => {
    let company: any = arrCompanies.find((element: any) => {
      if (element.licenseeName === licenseeName)
        return true;
    })
    if (company) {
      setDetails(company);
    }
  };
  
  const handleDatatables = (row: any) => {
    showDetails(row.licenseeName)
    setShowDataTable(false)
  }
  
  const CompanynLink = (row: any) => (
    <div
    onClick={() => handleDatatables(row)}
      style={{
        textDecoration: "underline",
        color: "#73d4eb",
        lineHeight: 2,
        cursor: "pointer",
      }}
    >
      {row.licenseeName} 
    </div>
  );
  
  const columns: TableColumn<any>[] = [
    {
      name: 'GLN',
      selector: (row: { licenseeGLN: any }) => row.licenseeGLN || '---',
      sortable: true,
      wrap: true,
      center: true,
      width: '240px',
    },
    {
      name: 'Nome da Empresa',
      selector: (row: { licenseeName: any }) => row.licenseeName || '---',
      maxWidth: '240px',
      wrap: false,
      sortable: false,
      cell: (row: any) => <CompanynLink {...row} />,
    },
    {
      name: 'Cidade',
      selector: (row: { address: any }) => row.address.addressSuburb!!?row.address.addressSuburb.value :"---",
      maxWidth: '150px',
      wrap: false,
      sortable: false,
    },
    {
      name: 'País',
      selector: (row: { address: any }) => getCountryName(row.address.countryCode) || '---',
      maxWidth: '150px',
      wrap: false,
      sortable: false,
    },
  ];

  

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    send()
  }


  async function send() {
    searchCode();
  }

  const handleIconAndText = () => {
    if(ShowIcon === true){
        setShowText ('Ocultar campos opcionais')
        setShowImage(iconMinus)
        setShowIcon(false)
    }else{
        setShowText ('Mostrar campos opcionais')
        setShowImage(iconPlus)
        setShowIcon(true)
    }
  }

  const validate = () => {

    let message: string = '';
    let titleMessage: string = '';
    let error: boolean  = false;
    setView({ error: false, content: false });

    const select = document.getElementById('selectIdCountry') as HTMLSelectElement;
    const input = document.getElementById('empresaId') as HTMLInputElement;
    const currentValueSelect = select.value;
    const currentInputValue = input.value.trim();

    if (currentInputValue.trim() === '') {
        error = true;
        titleMessage = 'Nenhum código foi digitado no campo de busca';
        message = 'Por favor, informe ao menos um código para consulta';
    }
    if(currentValueSelect == '' || currentValueSelect == null){
      error = true;
      titleMessage = 'Nenhum país foi selecionado';
      message = 'Por favor, informe um páis para a busca';
    }
    return{message, titleMessage, error};
    
};


const searchCode = async () => {
  let valid = validate();

  if (!valid.error) {
    let toastId = showWait('Aguarde, enquanto realizamos sua pesquisa.');
    setIsDisabled(true);
    let body = {
      'licenseeName': code,
      'countryCode': country,
      'addressLocality': valueCity,
      'postalCode': valueCep,
      'streetAddress': valueAdress,
      'size': 20,
    };

    const response: any = await getVerifyCompanies(body, jwt);
    dismissToast(toastId);
    setIsDisabled(false);

    if (response.status === 200) {
      if (response.data != null) {

        if (response.data.licences.length === 0)
           return setView({ error: true, content: false }), setErrorTitle(`Desculpe, não encontramos nenhum resultado.`),setErrorMessage('Por favor, verifique se há erros de digitação.');

        myRef.current.scrollIntoView();
        const licences = response.data.licences;
        setArrCompanies(licences);
        setdataPrepared(licences);
       console.log(licences)
        setSearchResultText(
          `Sua busca por ${code} retornou ${response.data.totalRecords} resultados. Este serviço mostra os primeiros 20 registros que correspondem aos termos da busca. Use os campos opcionais para refinar seus critérios de busca.`
        );

        setView({ error: false, content: true });
        setState({ searchDone: true });
      } else {
        setView({ error: true, content: false });
        setErrorTitle(`O código fornecido não foi encontrado.`);
      }
    } else {
      if (response.code === 401) {
        navigate("/" + location.search);
      }

      showError(response.msg);
      setView({ error: false, content: false });
    }
  } else {
    setView({ error: true, content: false });
    setErrorTitle(valid.titleMessage);
    setErrorMessage(valid.message);
  }
};


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value; // A string selecionada (typeKey)

    // Apenas se o objeto existir no KeyTypesVerified
    if (selectedValue) {
      setCountry(selectedValue); // Armazena o objeto completo no estado
    }
  }




  return (

    <>


      <div className='bg-color-ui-1' >

        <div className='container'>

          <div className='row customWidth bg-color-white  px-4 rounded-lg' >
            <div className='col-12 col-md-12 mb-5' >
             <div className='customSpanOptions'>
                        <label onClick={handleIconAndText} className="form-check-label-custom">
                            <span>
                                <img width='20px' height='20px' src={ShowImage || iconPlus} />
                               {ShowText || 'Mostrar Campos Opcionais'}
                            </span>
                        </label>
                    </div>
              <form onSubmit={onFormSubmit}>
                <div id={`${ShowIcon === false ? 'customDisplay' : 'custom-responsi'}`}>
                    <div  className=" custom-select1 form-group mb-0">
                                    <select
                                        style={{height:'46px', padding:'0px', paddingLeft:'10px'}}
                                        className="form-control"
                                        id='selectIdCountry'
                                        onChange={handleSelectChange}
                                        defaultValue={''}
                                    >
                                         <option disabled value={''}>Selecione um país</option>
                                            {countries.map((item)=>(
                                                <option key={item.name} value={item.code}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </select>

                        {ShowIcon === false &&
                        <div style={{marginTop:'10px'}}>
                            <div className='custom-border5'  style={{height:'44px',  marginBottom:'10px'}}>
                                <label className='container_my_input_custom custom-height-container-input'>
                                <BsSearch />
                                    <input
                                    id='inputId'
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    placeholder="Endereço (opcional)"
                                    onChange={({ target }) => setValueAdress(target.value)}
                                    value={valueAdress}
                                    className="especial_input"
                                    style={{outline:'none', border: 'none'}}
                                    disabled={isDisabled}
                                    />

                                </label>
                            </div>
                            <div className='custom-border5' style={{height:'44px', marginBottom:'10px'}}>
                                <label className='container_my_input_custom custom-height-container-input'>
                                <BsSearch />
                                    <input
                                    id='inputId'
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    placeholder="CEP (Opcional)"
                                    onChange={({ target }) => setValueCep(target.value)}
                                    value={valueCep}
                                    className="especial_input"
                                    style={{outline:'none', border: 'none'}}
                                    disabled={isDisabled}
                                    />

                                </label>
                            </div>
                            <div className='custom-border5'style={{height:'44px'}}>
                                <label className='container_my_input_custom custom-height-container-input'>
                                <BsSearch />
                                    <input
                                    id='inputId'
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    placeholder="Cidade (Opcional)"
                                    onChange={({ target }) => setValueCity(target.value)}
                                    value={valueCity}
                                    className="especial_input"
                                    style={{outline:'none', border: 'none'}}
                                    disabled={isDisabled}
                                    />

                                </label>
                            </div>
                        </div>
                    }
                        </div>
                    
                    <div className='custom-container-input' style={{display:'flex'}}>
                      <div className='custom-border5'
                      >
                          <label
                          
                          className='container_my_input_custom custom-height-container-input'>
                          <BsSearch />
                            <input
                            id='empresaId'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            placeholder="Nome da empresa"
                            onChange={({ target }) => setCode(target.value)}
                            value={code}
                            className="especial_input"
                            style={{outline:'none', border: 'none'}}
                            disabled={isDisabled}
                            />

                          </label>
                      </div>
                      <div>
                        <button
                            type="submit"
                            className="custom-button-especial"
                            disabled={isDisabled}
                            onClick={()=>setDetails(null)}
                        >
                            Buscar
                        </button>
                      </div>
                    </div>

                </div>
              </form>
            </div>


            <div ref={myRef}></div>



          </div>

        </div>
        
        {view.error && (
          <div className="row">
            <Warning
              type="error"
              showpick={false}
              title={errorTitle}
              text={errorMessage}
            />
          </div>
        )}





      </div>
      {view.content && (
            <div className="col-12 mt-4" style={{ maxWidth: '1100px', overflow: 'hidden', padding: '0' }}>
                <div className="bg-color-ui-1 pb-5 wp-home">
                  <div className="container">
                    <div
                      className="bg-color-white py-5 px-4 rounded-lg"
                      style={{ width: '100%' }}
                    >
                      <div className="row">
                        <NewWarning
                          type="success"
                          showpick={false}
                          title=""
                          text={searchResultText}
                        />
                      </div>

                      <div className="row" style={{ width: '100%' }}>
                        <div className="col-12 col-md-6 col-lg-8">
                          <h2 style={{ marginTop: 0, marginBottom: '0.5rem' }}>Resultado da Pesquisa</h2>
                          <p className="little_text">Clique no nome da empresa para visualizar mais detalhes.</p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4">
                    
                          {view.content && (<ExportDetail dataPrepared={dataPrepared} itemType={'VERIFY_COMPANY'} />)}

                        </div>

                      </div>
                    </div>

                  
                    {showDataTable && arrCompanies && arrCompanies.length > 0 && (
                      <div className="px-0 bg-color-white">
                        <div className="col-12 px-0" style={{ borderTop: 'solid 1px #d8d8d8' }}>
                        <DataTable
                            columns={columns}
                            data={arrCompanies}
                            pagination
                            responsive
                            noDataComponent="Sem registros para exibir"
                            highlightOnHover
                            customStyles={customStyles}
                            paginationComponent={BootyPagination}
                          />

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              
            </div>
          )}


      {details && !showDataTable && (
        <div className="bg-color-ui-1 pb-5 wp-home mt-4">
          <div className="container">
            <div
              style={{ paddingBottom: 10, fontSize: '14pt', color: '#0c9ae3', cursor: 'pointer' }}
              onClick={() => {
                setShowDataTable(true);
              }}
            >
              &lt; Voltar para o resultado de busca
            </div>

            <div className="row bg-color-white py-2 px-4 rounded-lg" style={{ width: '100%' }}>
              <div className="col-12 px-0">
                <CompanyResults data={details} />
              </div>
            </div>
          </div>
        </div>
      )}

      

      
    </>
  );


};

export default SearchOneFindCompany;



