import * as xlsx from "xlsx"
import * as FileSaver from "file-saver";


export function exportFileTestVerifyCompany(dataPrepared:any, filetype:string, numberMaxProduct:number){
  let headers =[]
  headers.push("Nome da Empresa");
  headers.push("Endereço");
  headers.push("Site");
  headers.push("Tipo de Prefixo");
  headers.push("GLN");
  headers.push("Organização Membro GS1");
  var item: any = exportFileFrontEndOtherKey(dataPrepared)
 
  headers.push("Erro");

  let ws;
  ws =xlsx.utils.json_to_sheet(item);

  
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(wb, { bookType: filetype=="xls"?"xls":"csv", type: "array" });
  const data = new Blob([excelBuffer]);
  FileSaver.saveAs(data, "arquivo" + "."+filetype);
}


function concatenateEndereco(address:any){
  let retorno ="";

  if (!!address.postalName) {
      retorno += address.postalName.value;
    }
    if (!!address.countryCode) {
      retorno += address.countryCode;
    }
    if (!!address.addressRegion) {
      retorno += address.addressRegion.value;
    }
    if (!!address.addressLocality) {
      retorno += address.addressLocality.value;
    }
    if (!!address.streetAddress) {
      retorno += address.streetAddress.value;
    }
    if (!!address.streetAddressLine2) {
      retorno += address.streetAddressLine2.value;
    }
    if (!!address.addressSuburb) {
      retorno += address.addressSuburb.value;
    }

    return retorno
}





export function exportFileFrontEndOtherKeyError(data:any){

  let item={
    'GLN': data.gln, 
    'Erro': "Não foi possível encontrar esta chave" 
  }
   
  return item;
}

export function exportFileFrontEndOtherKey(lista:any){
  let ret: any[] =[]
  lista[0].forEach((data:any )=>{
    var site =""
    if (!!data.contactPoint) {
      if (!!data.contactPoint[0]) {
        site = data.contactPoint[0].website
      }
    }

    let item={
      'Nome da Empresa': data.licenseeName, 
      'Endereço': concatenateEndereco(data.address), 
      'Site': site, 
      'Tipo de chave': data.licenceType, 
      'Global Location Number (GLN)': data.licenseeGLN, 
      'Organização Membro GS1': data.licensingMO.moName ,
      'Erro': ""    
    }
    ret.push(item)
  })
 
   
  return ret;
}

function merge(obj1: any, obj2: any) {
  let merged = {
    ...obj1,
    ...obj2
  };
  Object.keys(obj1).filter(k => obj2.hasOwnProperty(k)).forEach((k) => {
    merged[k] = obj1[k] + "," + obj2[k]
  })
  return merged
}
