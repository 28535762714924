import React, { useState } from "react";
import Modal from "react-modal";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube
} from "react-icons/bs"
import { IoMdClose } from "react-icons/io"
import Icon from './linkedinIcon'
import { FaWhatsapp } from "react-icons/fa";

const BottomBar = () => {

  const [open, setOpen] = useState(false)
  function close() {
    setOpen(false)
  }
  function openModal() {
    setOpen(true)
  }

  return (

      <footer>
        <div className="footer pt-7">
          <div className="container">
            <div className="row">
              <div className="col-12">

                <div className="row pt-4">

                  <div className="col-md-3 col-12 d-md-block">
                    <ul className="list-social-share">
                      <li>
                        <a href="http://www.linkedin.com/company/gs1-brasil" title="Linkedin" target="_blank" rel="noreferrer">
                            <svg viewBox="0 0 512 512">
                                <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm-74.390625 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0"></path>
                            </svg>
                        </a>
                      </li>
                      <li>
                        <a href="http://twitter.com/gs1br" title="Twitter" target="_blank" rel="noreferrer">
                            <svg viewBox="0 0 512 512">
                                <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm116.886719 199.601562c.113281 2.519532.167969 5.050782.167969 7.59375 0 77.644532-59.101563 167.179688-167.183594 167.183594h.003906-.003906c-33.183594 0-64.0625-9.726562-90.066406-26.394531 4.597656.542969 9.277343.8125 14.015624.8125 27.53125 0 52.867188-9.390625 72.980469-25.152344-25.722656-.476562-47.410156-17.464843-54.894531-40.8125 3.582031.6875 7.265625 1.0625 11.042969 1.0625 5.363281 0 10.558593-.722656 15.496093-2.070312-26.886718-5.382813-47.140624-29.144531-47.140624-57.597657 0-.265624 0-.503906.007812-.75 7.917969 4.402344 16.972656 7.050782 26.613281 7.347657-15.777343-10.527344-26.148437-28.523438-26.148437-48.910157 0-10.765624 2.910156-20.851562 7.957031-29.535156 28.976563 35.554688 72.28125 58.9375 121.117187 61.394532-1.007812-4.304688-1.527343-8.789063-1.527343-13.398438 0-32.4375 26.316406-58.753906 58.765625-58.753906 16.902344 0 32.167968 7.144531 42.890625 18.566406 13.386719-2.640625 25.957031-7.53125 37.3125-14.261719-4.394531 13.714844-13.707031 25.222657-25.839844 32.5 11.886719-1.421875 23.214844-4.574219 33.742187-9.253906-7.863281 11.785156-17.835937 22.136719-29.308593 30.429687zm0 0"></path>
                            </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/gs1brasil/" title="Instagram" target="_blank" rel="noreferrer">
                            <svg viewBox="0 0 512 512">
                                <path d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"></path>
                                <path d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"></path>
                                <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"></path>
                            </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://pt-br.facebook.com/gs1brasil" title="Facebook" target="_blank" rel="noreferrer">
                            <svg viewBox="0 0 512 512">
                                <path d="m512 256c0-141.4-114.6-256-256-256s-256 114.6-256 256 114.6 256 256 256c1.5 0 3 0 4.5-.1v-199.2h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5c107-30.7 185.3-129.2 185.3-246.1z"></path>
                            </svg>
                        </a>
                      </li>
                      <li>
                        <a href="http://www.youtube.com/gs1brasil" title="YouTube" target="_blank" rel="noreferrer">
                            <svg viewBox="0 0 512 512">
                                <path d="m224.113281 303.960938 83.273438-47.960938-83.273438-47.960938zm0 0"></path>
                                <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm159.960938 256.261719s0 51.917969-6.585938 76.953125c-3.691406 13.703125-14.496094 24.507812-28.199219 28.195312-25.035156 6.589844-125.175781 6.589844-125.175781 6.589844s-99.878906 0-125.175781-6.851562c-13.703125-3.6875-24.507813-14.496094-28.199219-28.199219-6.589844-24.769531-6.589844-76.949219-6.589844-76.949219s0-51.914062 6.589844-76.949219c3.6875-13.703125 14.757812-24.773437 28.199219-28.460937 25.035156-6.589844 125.175781-6.589844 125.175781-6.589844s100.140625 0 125.175781 6.851562c13.703125 3.6875 24.507813 14.496094 28.199219 28.199219 6.851562 25.035157 6.585938 77.210938 6.585938 77.210938zm0 0"></path>
                            </svg>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-utility-link">
                              <li>
                                  <a href="javascript:;" title="Termo de uso" className="utility-link" onClick={openModal}>Termo
                                    de uso 
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.gs1br.org/politica-de-privacidade" target="_blank" rel="noreferrer" className="utility-link" title="Politica de privacidade">
                                    Politica de privacidade
                                  </a>
                              </li>
                              
                              <li>
                                  <a href="https://www.gs1br.org/sobre-cookies" target="_blank" rel="noreferrer" className="utility-link" title="Sobre Cookies">
                                    Sobre Cookies
                                  </a>
                              </li>
                            </ul>
                        </div>
                        <div className="col-12 d-none d-md-block">
                            <p className="text-footer">GS1 é uma marca registrada da GS1 AISBL. v3</p>
                            <p className="text-footer">Todos os conteúdos são protegidos por direitos autorais © GS1 AISBL 2020 .</p>
                        </div>
                    </div>
                  </div>


                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-12">
                          <h6 className="mt-0 title-phone pr-1">Central de Relacionamento e
                            <a href="https://wa.me/551140001936" target="_blank" rel="noreferrer">
                              {' '}<FaWhatsapp/> WhatsApp
                            </a>                                                                                                               
                          </h6>
                          <p className="mt-0 title-phone">
                              <a href="http://chat.gs1br.org/" target="_blank" rel="noreferrer">Chat Online</a>
                          </p>
                          <p className="mt-0 title-phone">
                            Telefone:
                            <a href="tel:+551140001936" target="_blank" rel="noreferrer">11 4000.1936</a>
                          </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-md-none d-block mt-2">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-footer">GS1 é uma marca registrada da GS1 AISBL. v3</p>
                            <p className="text-footer">Todos os conteúdos são protegidos por direitos autorais © GS1 AISBL 2020 .</p>
                        </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={open}
          onRequestClose={close}
          ariaHideApp={false}
          contentLabel="Selected Option"
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex:1000,
              backgroundColor: "#262626CC",
            },
            content: {
              width: "70vw",
              height: "89vh",
              marginTop: 0,
              marginBottom: 0,
              marginLeft: "auto",
              marginRight: "auto",
              zIndex:1000,
              border: "1px solid #ccc",
              background: "#fff",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: "4px",
              outline: "none",
              padding: "20px",
            },
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              rowGap: "1.9rem",
            }}
          >
            <div>
              <span
                style={{ position: "absolute", right: 0, top: 0, padding: "0px" }}
              >
                <IoMdClose onClick={close} />
              </span>
            </div>

            <div
              style={{
                width: "100%",
                paddingBottom: "1.4rem",
                borderBottom: "1px solid #D8D8D8",
              }}
            >
              <span style={{ fontSize: "500", color: "#002C6C" }}>
                TERMOS DE USO DA SOLUÇÃO Verified by GS1 - Versão 3.0
              </span>
            </div>
            <div style={{ padding: "0 2rem" }}>
              <p className="p">
                <strong>GS1 BRASIL – ASSOCIAÇÃO BRASILEIRA DE AUTOMAÇÃO</strong>, com sede à Rua Henrique Monteiro, no 79, Bairro Pinheiros,
                CEP: 05.423-020, Cidade São Paulo/SP, inscrita no CNPJ sob o no 53.197.141/0001-02, neste ato representada de acordo com seus
                documentos societários, doravante denominada simplesmente <strong>GS1 BRASIL</strong>.
              </p>
              <p className="p">
                Os Termos de Uso (“<strong>Termos de Uso</strong>”) regulam a adesão do <strong>DESTINATÁRIO</strong> à Solução Verified by <i>GS1</i>, ofertada pela <strong>GS1 BRASIL</strong> e o funcionamento
                da Solução. O <strong>DESTINATÁRIO</strong> é vinculado à <strong>GS1 BRASIL</strong>. A adesão do <strong>DESTINATÁRIO</strong> aos Termos de Uso se dará mediante a concordância por meio
                eletrônico em campo específico, cujo registro de aceite é armazenado pela <strong>GS1 BRASIL</strong>.
              </p>
              <p className="p">Os Termos de Uso são formados pelas cláusulas e condições a seguir descritas.</p>
              <p className="p"><strong>1. DEFINIÇÕES</strong></p>
              <p className="p">1.1 Os seguintes termos têm os seguintes significados:</p>

              <ol className="ul" type="a">
                <li>Provedor de Dados: uma parte que foi contratada por um &quot;Proprietário de marca&quot; para manter, gerenciar e/ou divulgar os dados de produtos nas plataformas GS1 (incluindo, mas
                  não de limitando a provedores de conteúdo).</li>
                <li>&quot;GTIN&quot;: a chave de identificação GS1, que foi atribuída por uma MO (<i>Member Organization</i>) da GS1 Global e disponibilizados para o Registro GS1.</li>
                <li>&quot;Atributos Verified by GS1&quot;: São os atributos mínimos do produto identificados com uma chave única GS1 (GTIN – Global Trade Item Number) nacionais e internacionais
                  cadastrados no Registro Global (GRP – Global Registry Plataform). Os dados do produto que são propriedade do proprietário da marca ou em sua licença como atributos de
                  dados (como texto, imagens ou em qualquer outra forma) e que são fornecidos por ele mesmo ou para o proprietário da marca à MO (<i>Member Organization</i>) para publicação e
                  entrada do Registro GS1.</li>
                <li>“Atributos Cadastro Nacional de Produto”: São os atributos mínimos do produto mais atributos complementares registrados no Cadastro Nacional de Produtos da GS1 Brasil,
                  que são fornecidos por ele mesmo ou para o proprietário da marca à GS1 Brasil.</li>
                <li>“Atributos GEPIR”: Valida a autenticidade do GTIN em uma base global.</li>
                <li>&quot;DESTINATÁRIO&quot;: uma pessoa física ou jurídica vinculada à <strong>GS1 BRASIL</strong>  que visualiza e/ou usa os dados e aceita esse Termo de Uso.</li>
                <li>&quot;Fornecedor de Dados de Produto&quot;: ": qualquer parte que fornece dados, incluindo, mas não se limitando a, proprietários de marcas, revendedores e importadores, bem como qualquer agente do proprietário da marca que esteja autorizado pelo proprietário da marca a criar, manter, gerenciar e / ou entregar dados em nome do proprietário da marca (por exemplo, provedor de conteúdo).</li>
                <li>&quot;GCP&quot;: Prefixo GS1 da Empresa.</li>
                <li>&quot;GLN&quot;: Número de localização global.</li>
                <li>&quot;GS1 BRASIL&quot;: uma associação brasileira de direito privado, regida pela lei brasileira, com sede à Rua Henrique Monteiro, nº 79, Bairro Pinheiros, CEP:  05.423-020, Cidade São Paulo/SP.</li>
                <li>&quot;GTIN&quot;: Número Global de Item Comercial.</li>
                <li>&quot;Licença inativa&quot;: uma licença GCP ou licença GTIN única que expirou ou foi rescindida de acordo com os regulamentos aplicáveis.</li>
                <li>&quot;Metadados&quot;: todos os dados que surgem no decorrer do uso do Registro GS1 pelo destinatário dos dados, incluindo estatísticas que documentam as conexões entre o Registro GS1 e as informações do<strong>DESTINATÁRIO</strong> quando usa o Registro GS1, bem como informações que o <strong>DESTINATÁRIO</strong> insere em um Registro GS1.</li>
                <li>&quot;Organização Membro GS1&quot;: uma empresa membro da GS1 na forma em que este termo é comumente entendido em relação à <strong>GS1 BRASIL</strong>.</li>
                <li>“Parte”: GS1 e/ou DESTINATÁRIO.</li>
                <li>“Proprietário da marca”: um fabricante ou distribuidor de produtos de marca própria ou um agente que reportam os Dados do Produto no Registro GS1 publicados na solução <i>Verified by GS1</i>.</li>
                <li>&quot;Registro GS1&quot;: a plataforma nomeada GS1 Registries, são registros para os diferentes tipos de Chaves e Licenças GS1 gerenciadas pela GS1 – um Registro de Licença para licenças de GCPs e GTINs únicos, um Registro GTIN para GTINs e seus dados de produtos associados e assim por diante.</li>
                <li>“Solução”: o &quot;<i>Verified by GS1</i>&quot;é uma solução oferecida pela GS1 BRASIL que possibilita a consulta de GTINs em três locais de dados distintos Registro GS1, Cadastro Nacional de Produtos e GEPIR. A solução é ofertada apenas as empresas vinculadas a GS1 BRASIL. O uso da Solução pelo DESTINATÁRIO pressupõe a adesão aos Termos de Uso, em conformidade com as políticas internas da GS1 BRASIL.</li>
                <li>“Termos de Uso”: o presente instrumento que rege a adesão do <strong>DESTINATÁRIO</strong> à Solução “<i>Verified by GS1</i>”, ofertada pela <strong>GS1 BRASIL</strong> e o funcionamento da Solução.</li>
                <li>&quot;Usuário&quot;: qualquer pessoa física ou jurídica que obtém acesso ao Registro GS1 e ao serviço por meio da conta do <strong>DESTINATÁRIO</strong> ou os utiliza. Os atos e ações do Usuário são de
                  responsabilidade integral e exclusiva do <strong>DESTINATÁRIO</strong>.</li>
                <li> Cadastro Nacional de Produtos: fonte de dados de produtos nacionais, que contemplam a chave única GTIN mais seus atributos fornecidos pelos Donos de Marca.</li>
                <li>GEPIR - é uma ferramenta desenvolvida para avaliar a autenticidade de códigos.</li>
              </ol>

            
               
                

              <ul className="numeric-decimals nestedcounterlist" >
                <li style={{ visibility: 'hidden' }}></li>
                <li style={{ paddingBottom: "10px" }}>
                  <strong>DO OBJETO</strong><br />
                  <ul style={{ paddingTop: "20px" }} >
                    <li>Pelo presente Instrumento, são instituídos os Termos de Uso para regular a adesão do <strong>DESTINATÁRIO </strong>à Solução “<i>Verified by GS1</i>”,
                      ofertada pela <strong>GS1 BRASIL</strong> e o funcionamento da Solução.</li>
                    <li>Desde que o <strong>DESTINATÁRIO</strong> aceite estes Termos de Uso, a <strong>GS1 BRASIL</strong> fornece ao <strong>DESTINATÁRIO</strong> a autorização para utilizar a Solução.</li>
                    <li>Todo o acesso e utilização da Solução pelo <strong>DESTINATÁRIO</strong> estão sujeitos aos presentes Termos de Uso e a todas as condições aqui referidas, bem como a todas as demais
                      condições e regras publicadas no site da <strong>GS1 BRASIL</strong> sobre a Solução. Estas regras são incorporadas por referência a estes Termos de Uso. A <strong>GS1 BRASIL</strong> pode alterar estes Termos de Uso a
                      qualquer momento, sem aviso prévio. <strong>O DESTINATÁRIO</strong> garante que todas as informações fornecidas à <strong>GS1 BRASIL</strong>, seja durante o registro ou posteriormente, estejam corretas, completas e
                      atualizadas em todos os momentos.</li>
                    <li>A <strong>GS1 BRASIL</strong> disponibiliza a Solução com a devida diligência e em conformidade com as leis e regulamentos aplicáveis.</li>
                    <li>Em nenhum momento a <strong>GS1 BRASIL</strong> garante que a Solução é infalivelmente segura, livre de erros ou funcionará sem interrupção</li>
                    <li>A <strong>GS1 BRASIL</strong> tem o direito de, a qualquer momento, alterar a Solução e/ou alterar o design ou a funcionalidade do Registro GS1, alterar sua apresentação externa ou
                      interromper as operações.</li>
                    <li>Como parte da Solução, a <strong>GS1 BRASIL</strong> concede ao <strong>DESTINATÁRIO</strong> uma licença não exclusiva, intransferível e não sublicenciável com o conteúdo do uso dos Dados do Produto de acordo com estes Termos de Uso, incluindo as
                      limitações resultantes da Cláusula 3.</li>
                    <li>A licença de uso concedida através deste Termo de Uso não é exclusiva, podendo a <strong>GS1 BRASIL</strong> negociar com terceiros ou promover outros tipos de exploração comercial, da maneira que melhor lhe convier.</li>
                    <li>A <strong>GS1 BRASIL</strong> se reserva ao direito de imediatamente remover o acesso do <strong>DESTINATÁRIO sem aviso prévio,</strong> se houver suspeita ou for comprovado que este não está cumprindo os presentes
                      Termos de Uso.</li>
                  </ul>
                </li>
                <li style={{ paddingBottom: "10px" }}>
                  <strong>DAS LIMITAÇÕES</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist" >
                    <li >
                      O <strong>DESTINATÁRIO</strong> não poderá:
                      <div className="divlist" style={{ paddingTop: "20px" }}>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>a) exibir, publicar ou usar os Dados do Produto de maneira falsa ou enganosa;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>b) usar os Dados do Produto de forma que os direitos de terceiros (incluindo o proprietário da marca registrada) ou as leis ou regulamentos aplicáveis sejam violados;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>c) modificar o conteúdo dos Dados do Produto por meio de publicação ou distribuição (a menos que o proprietário da marca registrada tenha dado permissão por escrito para isso);</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>d) usar ou publicar os Dados do Produto de uma maneira que sugira a aprovação do proprietário da marca registrada ou <strong>GS1 BRASIL</strong> (a menos que o proprietário da marca registrada ou <strong>GS1 BRASIL</strong> tenha aprovado expressamente por escrito);</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>e) exercer os direitos autorais ou qualquer outro direito de propriedade dos Dados do Produto;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>f) vender, sublicenciar, distribuir ou disponibilizar a terceiros de qualquer forma, exceto como produto;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>g) tornar os Dados do Produto acessíveis como parte de uma Solução que concorre com o Registro GS1;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>h) baixar Dados em uma extensão que exceda a extensão permitida pela Solução dentro de um período definido;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>i) decompilar, fazer engenharia reversa, alterar ou de qualquer forma modificar (ou tentar) no todo ou parte da Solução, do Registro GS1, do site, da solução ou qualquer software nele contido (incluindo o código-fonte do software usado para esta Solução) nem causar, permitir ou auxiliar outras pessoas, direta ou indiretamente a fazer o acima citado;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>j) comportar-se de forma que a operação livre de problemas da infraestrutura do site e/ou da solução seja afetada de forma adversa ou realizar qualquer ação que possa danificar ou prejudicar a segurança do site, da solução e/ou de qualquer rede conectada;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>k) usar o site e/ou solução para coletar informações pessoais de pessoas físicas sem o consentimento prévio da <strong>GS1 BRASIL</strong>;</div>
                        </span>
                        <span className="spanlist">
                          <div style={{ lineHeight:"40px", paddingLeft: "20px" }}>l) usar o site e/ou solução para coletar Metadados da <strong>GS1 BRASIL</strong> sem consentimento prévio.</div>
                        </span>
                      </div>
                    </li>
                    <li style={{  paddingTop: "20px" }}>As limitações descritas no item 3.1 acima sobreviverão ao encerramento e/ou rescisão do presente Termo de Uso.</li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>CONTA DE USUÁRIO</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>O <strong>DESTINATÁRIO</strong> é responsável por seu acesso e uso do site e da Solução por Usuários ou outros por meio da conta de usuário do <strong>DESTINATÁRIO</strong>. Com o cadastro, o <strong>DESTINATÁRIO</strong> receberá dados de login para uso
                      exclusivo do usuário nomeado. O <strong>DESTINATÁRIO</strong> tratará esses dados de login de maneira confidencial e informará imediatamente a <strong>GS1 BRASIL</strong> sobre qualquer uso não autorizado. A <strong>GS1 BRASIL</strong> verificará os dados pessoais de um Usuário em conformidade com os regulamentos de proteção de dados.</li>
                    <li>O <strong>DESTINATÁRIO</strong> é o único responsável pelos atos que o Usuário praticar em relação à Solução, Dados do Produto, Registro GS1, perante a <strong>GS1 BRASIL</strong>, dono da marca e quaisquer terceiros em geral, inclusive atos de representação do <strong>DESTINATÁRIO</strong>, não podendo se eximir de responsabilidade por tais atos de Usuário.</li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>GTINs INATIVOS</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>Na medida do aplicável, o <strong>DESTINATÁRIO</strong> deve informar seus fornecedores assim que a solução indicar que um GTIN está inativo e recomendar o contato da <strong>GS1 BRASIL</strong> para permitir rastreabilidade adequada.</li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>TARIFAS</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>Quando aplicável a <strong>GS1 BRASIL</strong> fixará a tarifa relativa à solução “<i>Verified by GS1</i>” de acordo com a tabela de preços fixada pela GS1 Brasil, levando em consideração a cláusula 15.5 abaixo e as suas regras internas.</li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>SUSPENSÃO E RESCISÃO</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>Se o <strong>DESTINATÁRIO</strong> violar estes Termos de Uso e/ou não mais estiver vinculado a <strong>GS1 BRASIL</strong>, a <strong>GS1 BRASIL</strong> terá o direito de cancelar/bloquear o acesso do <strong>DESTINATÁRIO</strong> à Solução com efeito imediato, por meio de uma notificação por escrito. O restabelecimento de acesso
                      à Solução se dará a critério da <strong>GS1 BRASIL</strong>, nos termos de suas normas internas.</li>
                    <li>
                      A <strong>GS1 BRASIL</strong> poderá deixar de ofertar a Solução de acordo com os seus próprios critérios e nos termos de suas políticas internas sem aviso prévio.
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>GARANTIAS</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>
                    O Registro GS1 representa Dados do Produto que não são de propriedade ou estão sob o controle da <strong>GS1 BRASIL</strong>. Portanto, os Dados do Produto são fornecidos "como estão". As verificações da Solução feitas pela <strong>GS1 BRASIL</strong> limitam-se a verificações lógicas e automatizadas e não incluem validações físicas ou legais da exatidão dos dados. Nem a <strong>GS1 BRASIL</strong>, nem as empresas membros da <strong>GS1 BRASIL, nem as Organizações Membro, nem o escritório global </strong>nem o proprietário da marca garantem de que os dados estão corretos, completos ou atualizados. A utilização dos Dados do Produto, da Solução e do site é por conta e risco exclusivo do <strong>DESTINATÁRIO</strong>. A <strong>GS1 BRASIL</strong> não representa e/ou garante que os dados verificados pela Solução são adequados para qualquer finalidade regulatória, incluindo, sem limitação, quaisquer requisitos de relatórios regulatórios no setor de saúde. A <strong>GS1 BRASIL</strong>não garante ou se responsabiliza, expressa, implícita ou em qualquer outra forma, e qualquer assunto, incluindo os Dados do Produto, a Solução e os websites disponíveis. Todas as garantias ou responsabilidade são expressamente renunciadas pela <strong>GS1 BRASIL</strong>.
                    </li>
                    <li>
                    As regras de acesso às informações e comercialização estão condicionadas às regras globais e a GS1 Brasil não pode garantir alterações sofridas ao longo do tempo.
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>LIMITAÇÃO DE RESPONSABILIDADE</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>
                      Na extensão permitida pela lei, a <strong>GS1 BRASIL</strong>, as empresas relativas ao Sistema GS1 no Brasil ou no exterior, os proprietários de marca e dos produtos não serão responsabilizados por qualquer dano,
                      incluindo, mas não se limitando a, danos atuais, diretos, consequentes ou incidentais decorrentes da utilização do site e/ou da solução  pelo <strong>DESTINATÁRIO</strong>, da utilização da Solução ou dos Dados de Produto ou da
                      recusa pela <strong>GS1 BRASIL</strong> do acesso do <strong>DESTINATÁRIO</strong> ao site, à Solução ou aos Dados de Produto.
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>INDENIZAÇÃO</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>
                      O <strong>DESTINATÁRIO</strong> indenizará, manterá indene e defenderá totalmente a <strong>GS1 BRASIL</strong>as empresas relativas ao Sistema GS1 no Brasil ou no exterior, bem como todos os diretores, órgãos, funcionários e representantes dos mencionados acima contra todas as reivindicações, ações judiciais, reivindicações por danos, responsabilidades, obrigações, perdas, acordos, julgamentos, custos e despesas (incluindo honorários advocatícios e custos razoáveis) reivindicados por terceiros em conexão com, em relação a, ou como resultado do uso do da Solução, dos Dados, dos Dados de Produto pelo  <strong>DESTINATÁRIO</strong> em descumprimento aos Termo de Uso ou de quaisquer leis e regulamentos aplicáveis.
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>DIREITO DE PROPRIEDADE INTELECTUAL</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">
                    <li>
                      Todos os direitos de propriedade intelectual, títulos e quaisquer outros direitos ao Site, o Registro GS1, a Solução e aos Dados de Produto pertencem exclusivamente à <strong>GS1 BRASIL</strong> ou seus licenciados e ao Escritório Global.
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>PROTEÇÃO DE DADOS</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">                    <li>
                  Ao Processar Dados Pessoais (informações relativas a uma pessoa identificada ou identificável, ou o conjunto de informações distintas que possam levar à identificação de uma determinada pessoa), cada Parte deve cumprir a legislação de proteção de dados pessoais aplicável.
                    </li>
                    <li>
                    Cada Parte deverá processar apenas os Dados Pessoais estritamente necessários para a execução destes Termos de Uso e manter registros escritos das atividades de processamento, incluindo sua duração, os terceiros eventualmente envolvidos, as categorias de Dados Pessoais, seus titulares e o(s) propósito(s) das atividades de processamento.
                    </li>
                    <li>
                    Direcionar ao controlador, o mais breve possível, eventuais pedidos de exercício de direitos dos titulares de Dados Pessoais.
                    </li>
                    <li>
                    Garantir que não pratica qualquer ação que coloque o controlador em violação de suas obrigações sob as leis de proteção de dados pessoais.
                    </li>
                    <li>
                      Informar imediatamente ao controlador qualquer acesso acidental, não autorizado ou ilegal, perda, destruição, roubo, uso, divulgação ou alteração de dados pessoais ou qualquer outra não conformidade com o Contrato (“<strong><span style={{ textDecoration: "underline" }}>Incidente de Segurança</span></strong>”), e fornecer toda a assistência solicitada pelo controlador e adotar medidas razoáveis para mitigar o respectivo risco e evitar recorrências.
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>CONFIDENCIALIDADE</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">                               <li>
                  As Partes manterão as informações confidenciais da outra Parte estritamente confidenciais e as usarão apenas para o fim e para o cumprimento de suas obrigações contratuais nos termos deste Instrumento ou no exercício de um direito baseado nestes Termos de Uso.
                    </li>
                    <li>
                    As Partes deverão tomar todas as medidas necessárias e obrigações estabelecidas neste termo de uso, para proteger contra a divulgação a terceiros as informações confidenciais e exclusivas da outra Parte.
                    </li>
                    <li>
                    As Partes não poderão explorar ou usar as informações confidenciais e exclusivas para quaisquer propósitos que não os necessários para o exercício dos direitos e obrigações nestes Termos de Uso
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>ANTICORRUPÇÃO</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">                        <li>
                  As Partes declaram e garantem que não admitem nem toleram condutas que possam caracterizar corrupção seja ela passiva ou ativa, seja por si e/ou por seus representantes, devendo envidar todos os esforços necessários, cuidado e diligência os quais deveria empregar nas atividades dos seus próprios negócios para que haja sempre o respeito às normas, políticas e legislações pertinentes. Ocorrendo fato dessa natureza, a presente relação contratual poderá ser rescindida imediatamente.
                    </li>
                    <li>
                    No desempenho das obrigações previstas no Termo de Uso, as Partes comprometem-se, por si, seus empregados, subcontratados e pessoas físicas ou jurídicas a eles relacionadas, a não pagar ou oferecer qualquer coisa de valor relevante, seja como compensação, presente ou contribuição ou valor em espécie, a qualquer pessoa ou organização, privada ou governamental, se tais pagamentos, contribuições e presentes forem ou puderem ser considerados ilegais ou duvidosos.
                    </li>
                  </ul>
                </li>

                <li style={{ paddingBottom: "10px" }}>
                  <strong>DISPOSIÇÕES GERAIS</strong><br />
                  <ul style={{ paddingTop: "20px" }} className="numeric-decimals nestedcounterlist">  
                   <li>
                   Todas as notificações a serem enviadas entre as Partes deverão ser feitas por escrito (incluindo e-mail e o site da ferramenta) para o endereço da outra Parte descrito no preâmbulo do presente Termos de Uso, para a sede da Parte ou para o endereço (e-mail) fornecido quando a Solução foi solicitada ou para um endereço que o usuário especifique em cada caso ou por meio do site.
                    </li>
                    <li>
                    Se qualquer cláusula destes Termos de Uso for nula, inválida, inexequível ou ilegal, as outras cláusulas permanecerão válidas e exequíveis na forma mais ampla permitida por lei.
                    </li>
                    <li>
                      Caso a <strong>GS1 BRASIL</strong> deixe de exercer um direito com base nestes Termos de Uso, isso não deve ser considerado uma renúncia a esse direito. Nenhuma renúncia de qualquer direito sob estes Termos de Uso é válida, a menos que a renúncia seja feita por escrito e assinada pela <strong>GS1 BRASIL</strong>.
                    </li>
                    <li>
                      O <strong>DESTINATÁRIO</strong> não cederá seus direitos e obrigações decorrentes destes Termos de Uso a terceiros ou os transferirá de qualquer outra forma, parcial ou integralmente, sem o consentimento prévio por escrito da <strong>GS1 BRASIL</strong>. <strong>A GS1 BRASIL</strong> pode transferir seus direitos e obrigações sob estes Termos de Uso para empresas integrantes de seu grupo econômico sem o consentimento do <strong>DESTINATÁRIO</strong>.
                    </li>
                    <li>
                      A <strong>GS1 BRASIL</strong> reserva-se o direito de alterar estes Termos de Uso de tempos em tempos. A <strong>GS1 BRASIL</strong> confirma que os Termos de Uso alterados serão comunicados ao <strong>DESTINATÁRIO</strong> antes de entrarem em vigor no site da GS1 BRASIL. Tais alterações entrarão em vigor para o <strong>DESTINATÁRIO</strong> quando as alterações forem publicadas no site.
                    </li>
                    <li>
                      Estes Termos de Uso estão sujeitos à legislação brasileira. Além disso, as Partes elegem o foro da cidade de São Paulo/SP para dirimir dúvidas ou conflitos na aplicação destes Termos de Uso.
                    </li>
                  </ul>
                </li>

              </ul>

              <p className="p">
                Aceite do <strong>DESTINATÁRIO:</strong>
              </p>
              <p className="p">
                O <strong>DESTINATÁRIO</strong> declara que leu, compreendeu e concorda com os Termos de Uso relativos à Solução “<i>Verified by GS1</i>”, devendo utilizar a Solução em conformidade com os Termos de Uso.
              </p>

              
            </div>

          </div>
        </Modal>

      </footer>
  );
};

export default BottomBar;